"use strict";

var _interopRequireDefault = require("/Users/chenzhuo/Desktop/NAMECZ/\u79C1\u4EBA\u5DE5\u4F5C/liuchao/iot_console/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));

var _default = {
  namespaced: true,
  state: {
    accessToken: _localstorage.default.get('ACCESS_TOKEN'),
    refreshToken: _localstorage.default.get('REFRESH_TOKEN'),
    routeToken: _localstorage.default.get('ROUTE_TOKEN', null),
    expireTime: _localstorage.default.get('EXPIRE_TIME', 0),
    user: _localstorage.default.get('USER'),
    permissions: _localstorage.default.get('PERMISSIONS'),
    routes: _localstorage.default.get('USER_ROUTER') || [],
    projectIdSelected: localStorage.getItem('projectIdSelected') ? Number(localStorage.getItem('projectIdSelected')) : '',
    projectPermision: _localstorage.default.get('projectPermision') || []
  },
  mutations: {
    setAccessToken: function setAccessToken(state, val) {
      _localstorage.default.save('ACCESS_TOKEN', val);

      state.accessToken = val;
    },
    setRefreshToken: function setRefreshToken(state, val) {
      _localstorage.default.save('REFRESH_TOKEN', val);

      state.refreshToken = val;
    },
    setExpireTime: function setExpireTime(state, val) {
      _localstorage.default.save('EXPIRE_TIME', val);

      state.expireTime = val;
    },
    setUser: function setUser(state, val) {
      _localstorage.default.save('USER', val);

      state.user = val;
    },
    setPermissions: function setPermissions(state, val) {
      _localstorage.default.save('PERMISSIONS', val);

      state.permissions = val;
    },
    setRoutes: function setRoutes(state, val) {
      _localstorage.default.save('USER_ROUTER', val);

      state.routes = val;
    },
    setRouteToken: function setRouteToken(state, val) {
      _localstorage.default.save('ROUTE_TOKEN', val);

      state.routeToken = val;
    },
    setProjectIdSelected: function setProjectIdSelected(state, val) {
      _localstorage.default.save('projectIdSelected', val);

      state.projectIdSelected = val;
    },
    setProjectPermision: function setProjectPermision(state, val) {
      _localstorage.default.save('projectPermision', val);

      state.projectPermision = val;
    }
  }
};
exports.default = _default;