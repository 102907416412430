"use strict";

var _interopRequireWildcard = require("/Users/chenzhuo/Desktop/NAMECZ/\u79C1\u4EBA\u5DE5\u4F5C/liuchao/iot_console/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("/Users/chenzhuo/Desktop/NAMECZ/\u79C1\u4EBA\u5DE5\u4F5C/liuchao/iot_console/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("/Users/chenzhuo/Desktop/NAMECZ/\u79C1\u4EBA\u5DE5\u4F5C/liuchao/iot_console/node_modules/core-js/modules/es6.array.iterator.js");

require("/Users/chenzhuo/Desktop/NAMECZ/\u79C1\u4EBA\u5DE5\u4F5C/liuchao/iot_console/node_modules/core-js/modules/es6.promise.js");

require("/Users/chenzhuo/Desktop/NAMECZ/\u79C1\u4EBA\u5DE5\u4F5C/liuchao/iot_console/node_modules/core-js/modules/es6.object.assign.js");

require("/Users/chenzhuo/Desktop/NAMECZ/\u79C1\u4EBA\u5DE5\u4F5C/liuchao/iot_console/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("qweather-icons/font/qweather-icons.css");

require("vue-resize/dist/vue-resize.css");

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./styles/element-variables.scss");

require("@/styles/index.scss");

require("@/theme/index.css");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _dayjs = _interopRequireDefault(require("dayjs"));

var _isoWeek = _interopRequireDefault(require("dayjs/plugin/isoWeek"));

var _lang = _interopRequireDefault(require("./lang"));

require("./icons");

require("./utils/error-log");

var _request = _interopRequireDefault(require("@/utils/request"));

var filters = _interopRequireWildcard(require("./filters"));

var _permissionDirect = require("./utils/permissionDirect");

var _mixins = require("./mixins");

var _TableEmpty = _interopRequireDefault(require("./components/TableEmpty"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vueResize2 = _interopRequireDefault(require("vue-resize"));

var _compositionApi = _interopRequireDefault(require("@vue/composition-api"));

var _uploadFile = _interopRequireDefault(require("./components/uploadFile"));

var _htmlToPdf = _interopRequireDefault(require("@/utils/htmlToPdf.js"));

// a modern alternative to CSS resets
// global css
//主题色修改
// import "../node_modules/echarts/map/js/world.js"; //echarts世界地图
_dayjs.default.extend(_isoWeek.default);

_vue.default.use(_compositionApi.default);

_vue.default.use(_vueResize2.default);

_vue.default.component("TableEmpty", _TableEmpty.default);

_vue.default.component("Pagination", _Pagination.default);

_vue.default.component("UploadFile", _uploadFile.default);

var Plugins = [_permissionDirect.hasPermission, _permissionDirect.hasNoPermission, _permissionDirect.hasAnyPermission];
Plugins.map(function (plugin) {
  _vue.default.use(plugin);
});

_vue.default.mixin(_mixins.myMixin);

_vue.default.use(_elementUi.default, {
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  },
  size: "mini"
});

_vue.default.prototype.$post = _request.default.post;
_vue.default.prototype.$get = _request.default.get;
_vue.default.prototype.$put = _request.default.put;
_vue.default.prototype.$delete = _request.default.delete;
_vue.default.prototype.$download = _request.default.download;
_vue.default.prototype.$downloadExcel = _request.default.downloadExcel;
_vue.default.prototype.$upload = _request.default.upload;
_vue.default.prototype.$login = _request.default.login; // register global utility filters

Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;

// 使用Vue.use()方法就会调用工具方法中的install方法
_vue.default.use(_htmlToPdf.default);

new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
});