"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var db = {
  save: function save(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  get: function get(key) {
    var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    // return JSON.parse(localStorage.getItem(key)) || defaultValue
    try {
      return JSON.parse(localStorage.getItem(key)) || defaultValue;
    } catch (error) {
      return defaultValue;
    }
  },
  remove: function remove(key) {
    localStorage.removeItem(key);
  },
  clear: function clear() {
    localStorage.clear();
  }
};
var _default = db;
exports.default = _default;