"use strict";

var _interopRequireDefault = require("/Users/chenzhuo/Desktop/NAMECZ/\u79C1\u4EBA\u5DE5\u4F5C/liuchao/iot_console/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("/Users/chenzhuo/Desktop/NAMECZ/\u79C1\u4EBA\u5DE5\u4F5C/liuchao/iot_console/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _index = require("@/utils/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "UploadFile",
  props: {
    accept: {
      type: String,
      default: null
    },

    /**
     * 数据格式
     * [
     *  {
     *   name:xxx,
     *   url:xxx
     *  }
     * ]
     **/
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      uploadurl: process.env.VUE_APP_BASE_API + "aiot-messagecenter/cloud/file/upload",
      fileList: [],
      progressData: {
        progress: 0,
        name: "",
        size: 0
      },
      progressFile: null
    };
  },
  computed: {
    fileSize: function fileSize() {
      return (this.progressData.size / 1024 / 1024).toFixed(2);
    }
  },
  watch: {
    fileList: {
      handler: function handler(newVal, oldVal) {
        this.$emit("success", newVal.filter(function (p) {
          return p.status == "success";
        }));
      },
      deep: true // 深度监听fileList对象内部属性的变化

    }
  },
  mounted: function mounted() {
    this.getFileList();
  },
  methods: {
    getFileList: function getFileList() {
      this.fileList = this.list.map(function (p) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, p), {}, {
          status: "success",
          uid: (0, _index.randomNum)()
        });
      });
    },
    beforeUpload: function beforeUpload() {},
    uploadChange: function uploadChange(file, fileList) {
      var flag = this.fileList.some(function (p) {
        return file.uid == p.uid;
      });

      if (!flag) {
        this.fileList.push(file);
      }
    },
    uploadResult: function uploadResult(res, uid, flag) {
      if (this.progressFile) {
        // 重新上传成功
        this.fileList.push((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.progressFile), {}, {
          url: res.data.data,
          status: flag ? "success" : "fail"
        }));
      } else {
        // 新文件上传成功
        this.fileList.forEach(function (p) {
          if (p.uid == uid) {
            p.url = res.data.data;
            p.status = flag ? "success" : "fail";
          }
        });
      }

      this.progressData = {
        progress: 0,
        name: "",
        size: 0
      };
      this.progressFile = null;
    },
    toUploadAbort: function toUploadAbort() {
      this.uploadAbort();
      this.progressData = {
        progress: 0,
        name: "",
        size: 0
      };

      if (this.progressFile) {
        //重新上传中断
        this.fileList.push(this.progressFile);
        this.progressFile = null;
      } else {
        //首次上传中断上传
        this.fileList[this.fileList.length - 1].status = "fail";
      }
    },
    delFile: function delFile(uid) {
      this.fileList = this.fileList.filter(function (p) {
        return p.uid != uid;
      });
    },
    uploadAgain: function uploadAgain(file) {
      this.progressFile = file;
      this.fileList = this.fileList.filter(function (p) {
        return p.uid != file.uid;
      });
      this.uploadFile({
        file: file.raw
      });
    },
    uploadFile: function uploadFile(option) {
      this.upload(option.file);
    },
    upload: function upload(file) {
      var _this = this;

      var formData = new FormData();
      var uid = file.uid;
      formData.append("uid", uid);
      formData.append("file", file);
      formData.append("bucket", "image/eds");
      this.$upload("aiot-messagecenter/cloud/file/upload", formData, function (event) {
        _this.progressData = {
          progress: event.loaded / event.total * 100 | 0,
          name: file.name,
          size: file.size
        };
      }, function (c) {
        _this.uploadAbort = c;
      }).then(function (res) {
        if (res.data.data) {
          _this.uploadResult(res, uid, true);
        } else {
          _this.uploadResult(res, uid, false);
        }
      });
    },
    download: function download(url) {
      window.open(url);
    }
  }
};
exports.default = _default2;