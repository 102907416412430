"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = numDefEmpty;

function numDefEmpty(num) {
  var def = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  if (num === undefined || num === null || num === '') {
    return def;
  }

  return num;
}