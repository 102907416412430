"use strict";

var _interopRequireDefault = require("/Users/chenzhuo/Desktop/NAMECZ/\u79C1\u4EBA\u5DE5\u4F5C/liuchao/iot_console/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));

var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));

var _LangSelect = _interopRequireDefault(require("@/components/LangSelect"));

var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));

var _Screenfull = _interopRequireDefault(require("@/components/Screenfull"));

var _HeaderSearch = _interopRequireDefault(require("@/components/HeaderSearch"));

//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default,
    LangSelect: _LangSelect.default,
    Screenfull: _Screenfull.default,
    Search: _HeaderSearch.default
  },
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.setting.sidebar;
    },
    avatar: function avatar() {
      // return require(`@/assets/avatar/${this.$store.state.account.user.avatar || 'default.jpg'}`)
      if (this.$store.state.account.user.avatar) {
        return this.$store.state.account.user.avatar;
      } else {
        return require("@/assets/avatar/default.jpg");
      }
    },
    username: function username() {
      return this.$store.state.account.user.username;
    },
    device: function device() {
      return this.$store.state.setting.device;
    }
  },
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.commit("setting/toggleSidebar");
    },
    setting: function setting() {
      this.$store.commit("setting/openSettingBar", true);
    },
    logout: function logout() {
      var _this = this;

      this.$delete("aiot-auth/signout").then(function () {
        _this.clean();
      }).catch(function () {
        _this.clean();
      });
    },
    clean: function clean() {
      _localstorage.default.clear();

      this.$store.dispatch("tagsView/delAllVisitedViews");
      this.$store.dispatch("tagsView/delAllCachedViews");
      this.$router.push("login");
      this.$message({
        message: "安全退出",
        type: "success"
      });
    },
    deleteCache: function deleteCache() {
      this.$confirm(this.$t("tips.confirmDeleteCache"), this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning"
      }).then(function () {
        _localstorage.default.remove("USER_ROUTER");

        _localstorage.default.remove("PERMISSIONS");

        location.reload();
      }).catch(function () {// do nothing
      });
    }
  }
};
exports.default = _default;