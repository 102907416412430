"use strict";

var _interopRequireDefault = require("/Users/chenzhuo/Desktop/NAMECZ/\u79C1\u4EBA\u5DE5\u4F5C/liuchao/iot_console/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  computed: {
    language: function language() {
      return this.$store.state.setting.language;
    }
  },
  methods: {
    // handleSetLanguage(lang) {
    //   this.$i18n.locale = lang
    //   this.$store.commit('setting/setLanguage', lang)
    //   this.$message({
    //     message: this.$t('tips.switchLanguageSuccess'),
    //     type: 'success'
    //   })
    // }
    handleSetLanguage: function handleSetLanguage(lang) {
      this.language = lang;

      _localstorage.default.save("LANGUAGE", lang);

      this.$store.commit("setting/setLanguage", lang);
      this.deleteCache();
      this.$message({
        message: this.$t("tips.switchLanguageSuccess"),
        type: "success"
      });
    },
    deleteCache: function deleteCache() {
      _localstorage.default.remove("USER_ROUTER");

      _localstorage.default.remove("PERMISSIONS");

      location.reload();
      this.$i18n.locale = lang;
    }
  }
};
exports.default = _default;